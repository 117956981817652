<template>
    <div class="error pa3">
      <div class="links pv3 f5 center w-100">
        <div class="h1 f1 fw2 black">404 Not Found</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Error-View',
  };
  </script>
  
  <style scoped>
  </style>
  