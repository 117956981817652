<template>
  <div class="home pa3">
    <h1>226 Clifton Place #4A</h1>
    <div class="contact ba ba-blue pa2 w-25-ns tc center"><a class="link blue button2 pv3 f5 b" target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=4a@226clifton.place">for rent by owner. contact via email:<span class="f3 b mt2"><br/>4a@226clifton.place</span></a></div>
    <div class="links pv3 f5 center w-70-ns w-100 dt">
      <a class="dtc v-mid mv1 link black pv3 button ba w-10-ns w-33 mh2" target="_blank" href="https://streeteasy.com/building/226-clifton-place/4a">StreetEasy Listing</a>
      <!-- <a class="dib mv1 link black pv3 button ba w-20 mh2" target="_blank" href="https://calendly.com/reah/4a">Book Showing</a> -->
      <a class="dtc v-mid mv1 link black pv3 button ba w-10-ns w-33 mh2" target="_blank" href="https://drive.google.com/file/d/1Cc7wBN61axX7WCy9igz7cohmTSS_tNmC/view?usp=sharing">Rental Application</a>
      <a class="dtc v-mid mv1 link black pv3 button ba w-10-ns w-33 mh2" target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=4a@226clifton.place&su=Showing%20request%20226%20Clifton%20Place,%204A&body=I%20want%20to%20schedule%20a%20showing%20please.%0A%0AContact%20Info:%0APhone%20Number:%0A%0A">Schedule Showing</a>
    </div>
    <PhotoGallery class="w-75-ns center"/>
    <div class="tl f4-ns f5 w-90 center">
      <div class="pv3-ns pv2">Welcome to 226 Clifton #4A  an optionally furnished, two bed/two bath, apartment with a private balcony in the top luxury building in Clinton-Hill/BedStuy. </div>
      <div class="pv3-ns pv2">Enter into a large entry foyer with the bedrooms on the left and a large open living room and kitchen on the right. The open kitchen has condo finishes with high-end appliances throughout. The king-sized master bedroom has a private windowed bathroom and the second bedroom can fit a queen. The owners have upgraded the closets throughout so there is plenty of storage! The second bathroom includes a bathtub/shower.
        There are 10’ ceilings making the space feel lofty! Additionally, you’ll have modern comforts like central AC, wide oak plank floors throughout, and in-unit washer/dryer.</div>
      <div class="pv3-ns pv2">226 Clifton Place is a pet-friendly, beautiful, high-end elevator condominium, with Virtual Doorman, located in Clinton Hill/Bedford Stuyvesant. The building is complete with wonderful amenities including a package room with cold storage, and bike storage. The welcoming and well-designed communal spaces include a residents’ lounge, a sunlit gym, and a dramatic rooftop terrace with spectacular Manhattan views. Additional amenities include a Café and a ground floor garden/courtyard with a barbecue grill.</div>
    </div>

    <div class="mt4-ns mt3">
      <iframe title="map" :width="$isMobile() ? '100%' : '600px'" :height="$isMobile() ? '350px' : '450px'" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.3419302292355!2d-73.95749258428668!3d40.6884664793345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b9443d62869%3A0xb209e11394954661!2s226%20Clifton%20Pl%2C%20Brooklyn%2C%20NY%2011216%2C%20USA!5e0!3m2!1sen!2sil!4v1661078013363!5m2!1sen!2sil" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

  </div>
</template>

<script>
import PhotoGallery from '../components/Carousel.vue';

export default {
  name: 'Home-View',
  data() {
    return {
    };
  },
  components: {
    PhotoGallery,
  },
};
</script>

<style scoped>
.button {
}
.button:hover {
  background: lightblue;
}
.contact:hover {
  background: lightblue;
}
</style>
