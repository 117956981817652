<template>
    <div class="carousel">
      <vueper-slides :bullets="false" :fixed-height="$isMobile() ? '500px' : '100vh'">
        <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :image="slide.pathLong"
            >
        </vueper-slide>
    </vueper-slides>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  name: 'PhotoGallery',
  data() {
    return {
      count: 0,
      slides: [],
    };
  },
  mounted() {
    this.importAll(require.context('../assets/pics/', true, /\.jpg$/));
    this.importAll(require.context('../assets/pics/', true, /\.png$/));
    this.importAll(require.context('../assets/pics/', true, /\.jpeg$/));
  },
  methods: {
    importAll(r) {
      r.keys().map((key) => (this.slides.push({ pathLong: r(key), pathShort: key })));
      console.log('slides, ', this.slides.length);
    },
  },
  components: { VueperSlides, VueperSlide },
};
</script>

<style scoped>
</style>
